import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { useGetPlayerKyc6MatchesPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Tippy,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DownloadIcon,
  EditIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { SubmitMatchDecisionForm } from '@/forms';
import { KYC6MatchTypes } from '@/globalTypes';
import { useBrandValueOptions } from '@/hooks/useBrands';
import useKyc6MatchReportDownloadLink from '@/hooks/useKyc6MatchReportDownloadLink';
import { assert } from '@/utils/error';
import { renderTypeOfMatch } from '@/utils/typeOfMatch';
import useGlobalPlayerKyc6Matches from './useGlobalPlayerKYC6Matches';

export const Fragment = graphql`
  fragment SanityGlobalPlayerKyc6MatchesBlock on SanityGlobalPlayerKyc6MatchesBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const GlobalPlayerKyc6MatchesBlock: FC<{
  block: Queries.SanityGlobalPlayerKyc6MatchesBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const { playerGlobalId } = useParams();
  const { generateDownloadLink } = useKyc6MatchReportDownloadLink();
  const getPlayerKyc6MatchesLink = useGetPlayerKyc6MatchesPageLink();

  assert(playerGlobalId, 'missing playerGlobalId');

  const {
    entries,
    brands,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
    hasAccess,
  } = useGlobalPlayerKyc6Matches(playerGlobalId);

  const brandValueOptions = useBrandValueOptions(brands);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'globalPlayerKyc6Matches',
    data: entries,
    fetching,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
    accessDenied: !hasAccess,
    schema: (s) => [
      s.stringValue({
        field: 'playerUUID',
        title: 'Player UUID',
        linkTo: ({ row }) => getPlayerKyc6MatchesLink(row.playerId) ?? '',
      }),
      s.selectValue({
        field: 'brand',
        title: 'Brand',
        options: brandValueOptions.options,
        getValue: ({ row }) => row.brand?.name,
      }),
      s.stringValue({
        field: 'score',
        title: 'Score',
      }),
      s.stringValue({
        field: 'pepTier',
        title: 'Tier',
      }),
      s.enumValue({
        field: 'typeOfMatch',
        title: 'Type of Match',
        filterField: 'kyc6MatchTypes',
        e: KYC6MatchTypes,
        wrap: ({ row }) => renderTypeOfMatch(row.typeOfMatch),
      }),
      s.dateTimeValue({
        field: 'lastSearchedAt',
        title: 'Last Searched At',
        fromFilterField: 'from',
        toFilterField: 'to',
      }),
      s.stringValue({
        field: 'resourceId',
        title: 'Resource ID',
        hidden: true,
      }),
      s.stringValue({
        field: 'version',
        title: 'Version',
      }),
      s.stringValue({
        field: 'matchDecision',
        title: 'Match Decision',
        wrap: ({ row }) => (
          <>
            {row.matchDecision || '-'}
            <ControlledModal
              content={
                <SubmitMatchDecisionForm
                  playerId={row.playerId}
                  resourceId={row.resourceId}
                  matchDecision={row.matchDecision}
                  fallback={() =>
                    // For some unknown reason refresh doesn't work here without a timeout
                    setTimeout(
                      () => refresh({ requestPolicy: 'network-only' }),
                      100,
                    )
                  }
                />
              }
            >
              <InlineIconButton>
                <Tippy content="Change Match Decision">
                  <span>
                    <EditIcon />
                  </span>
                </Tippy>
              </InlineIconButton>
            </ControlledModal>
          </>
        ),
      }),
      s.actionValue({
        field: 'actions',
        title: 'Actions',
        getValue: ({ row }) => (
          <>
            <InlineIconButton
              onClick={() =>
                generateDownloadLink({
                  resourceId: row.resourceId,
                  version: row.version,
                })
              }
            >
              <Tippy content="Download Match Report">
                <div>
                  <DownloadIcon />
                </div>
              </Tippy>
            </InlineIconButton>
          </>
        ),
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default GlobalPlayerKyc6MatchesBlock;
