import classNames from 'classnames';
import React, { ClipboardEvent, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { BaseWrapper } from './BaseWrapper';

type InviteByPhoneNumberFieldProps = {
  title: string;
  name: string;
  required: boolean;
};

export const InviteByPhoneNumberField = (
  props: InviteByPhoneNumberFieldProps,
) => {
  const { setValue, control } = useFormContext();

  const [options, setOptions] = useState<string[]>([]);
  const [pasted, setPasted] = useState(false);

  const onChangeTextarea = useCallback(
    (value: string) => {
      setPasted(false);
      // Split by comma, space, or new line
      const newValue = value.split(/,| |\n/);
      setOptions(newValue);
      setValue(props.name, newValue);
    },
    [setOptions],
  );

  const onPaste = useCallback(
    (e: ClipboardEvent) => {
      setPasted(true);
      const data = e.clipboardData.getData('Text');
      setOptions(options.concat(data));
    },
    [options, setOptions],
  );

  const renderFooter = useMemo(() => {
    const validOptions = options.filter((option) => option.trim() !== '');

    return (
      <div className="flex p-2 items-center text-sm text-gray-500">
        {validOptions.length} {validOptions.length === 1 ? 'number' : 'numbers'}
      </div>
    );
  }, [options]);

  return (
    <div className="flex flex-col md:flex-row md:space-x-4">
      <div className="w-full">
        <BaseWrapper title={props.title} name={props.name}>
          <Controller
            name={props.name}
            control={control}
            defaultValue={[]}
            render={() => (
              <div className="w-full mt-1">
                <textarea
                  className={classNames(
                    `w-full h-24 border-gray-300 text-xs rounded-t-md align-top`,
                  )}
                  placeholder="Type or paste your list of items here, separated by comma, space or new line"
                  value={pasted ? '' : options.join('\n')}
                  onChange={(e) => onChangeTextarea(e.target.value)}
                  onPaste={(e) => onPaste(e)}
                />
                <div className="flex p-1 pl-0 items-center rounded-b border-gray-300 h-9 border text-sm text-gray-500 border-t-0">
                  {renderFooter}
                </div>
              </div>
            )}
            rules={{
              validate: (value) => {
                const isValid = value.every((item: string) =>
                  isValidPhoneNumber(item),
                );
                return isValid || 'All entries must be numbers';
              },
            }}
          />
        </BaseWrapper>
      </div>
    </div>
  );
};
