import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useMutation } from 'urql';

import { useFeedbackMessages } from '@/bits';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SelectField,
  SelectOption,
  SubmitButton,
  TextField,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { AdjustmentType } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { toNumber } from '@/utils/to-number';
import {
  AdjustmentDepositMutation,
  AdjustmentDepositMutationVariables,
  AdjustmentWithdrawalMutation,
  AdjustmentWithdrawalMutationVariables,
} from './__generated__/PlayerCreateAdjustmentForm';

const query = graphql`
  query SanityPlayerCreateAdjustmentForm {
    sanityPlayerCreateAdjustmentForm {
      title {
        ...SanityLocaleString
      }
      actionLabel {
        ...SanityLocaleString
      }
      actionDeposit {
        ...SanityLocaleString
      }
      actionWithdraw {
        ...SanityLocaleString
      }
      amountLabel {
        ...SanityLocaleString
      }
      adjustmentTypeLabel {
        ...SanityLocaleString
      }
      selectAdjustmentType {
        ...SanityLocaleString
      }
      commentLabel {
        ...SanityLocaleString
      }
      amountInvalidNumber {
        ...SanityLocaleString
      }
      submit {
        ...SanityLocaleString
      }
    }
  }
`;

const adjustmentWithdrawalMutation = gql`
  mutation AdjustmentWithdrawal(
    $playerId: ID!
    $amount: PositiveBigDecimal!
    $adjustmentType: AdjustmentType!
    $comment: String
  ) {
    adjustmentWithdrawalV2(
      playerId: $playerId
      amount: $amount
      adjustmentType: $adjustmentType
      comment: $comment
    ) {
      transactionId
    }
  }
`;

const adjustmentDepositMutation = gql`
  mutation AdjustmentDeposit(
    $playerId: ID!
    $amount: PositiveBigDecimal!
    $adjustmentType: AdjustmentType!
    $comment: String
  ) {
    adjustmentDepositV2(
      playerId: $playerId
      amount: $amount
      adjustmentType: $adjustmentType
      comment: $comment
    ) {
      transactionId
    }
  }
`;

const useOptions = ({
  form,
}: {
  form: Queries.SanityPlayerCreateAdjustmentFormQuery['sanityPlayerCreateAdjustmentForm'];
}) => {
  const { t } = useTranslate();

  return useMemo(() => {
    if (form) {
      const actionOptions: ActionOption[] = [
        {
          label: t(form.actionDeposit),
          value: 'DEPOSIT',
        },
        {
          label: t(form.actionWithdraw),
          value: 'WITHDRAWAL',
        },
      ];

      const adjustmentTypeOptions: SelectOption[] = [
        {
          label: t(form.selectAdjustmentType),
          value: '',
        },
        ...Object.values(AdjustmentType).map((adjustmentType) => ({
          label: adjustmentType,
          value: adjustmentType,
        })),
      ];

      return {
        actionOptions,
        adjustmentTypeOptions,
      };
    }

    return { actionOptions: [], adjustmentTypeOptions: [] };
  }, [form, t]);
};

type Action = 'DEPOSIT' | 'WITHDRAWAL';
type ActionOption = SelectOption & { value: Action };

const PlayerCreateAdjustmentForm: FC<{ playerId: string }> = ({ playerId }) => {
  const staticData =
    useStaticQuery<Queries.SanityPlayerCreateAdjustmentFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const { t } = useTranslate();
  const feedbackMessages = useFeedbackMessages();

  const form = staticData.sanityPlayerCreateAdjustmentForm;

  const { actionOptions, adjustmentTypeOptions } = useOptions({ form });

  const [adjustmentWithdrawalState, adjustmentWithdrawal] = useMutation<
    AdjustmentWithdrawalMutation,
    AdjustmentWithdrawalMutationVariables
  >(adjustmentWithdrawalMutation);

  const [adjustmentDepositState, adjustmentDeposit] = useMutation<
    AdjustmentDepositMutation,
    AdjustmentDepositMutationVariables
  >(adjustmentDepositMutation);

  const defaultValues = {
    action: 'DEPOSIT' as 'DEPOSIT' | 'WITHDRAWAL',
    amount: '',
    comment: '',
    adjustmentType: '' as AdjustmentType | '',
  };

  const onSubmit = (values: typeof defaultValues) => {
    if (!values.adjustmentType) {
      return;
    }

    const amount = toNumber(values.amount);

    if (!amount) {
      setErrorMessage(t(form?.amountInvalidNumber));
      return;
    }

    const variables = {
      playerId,
      comment: values.comment,
      amount,
      adjustmentType: values.adjustmentType,
    };

    if (values.action === 'DEPOSIT') {
      return adjustmentDeposit(variables).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          feedback.success(t(feedbackMessages.success));
          close();
        }
      });
    }

    return adjustmentWithdrawal(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success(t(feedbackMessages.success));
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid sm:grid-cols-2 gap-6"
        >
          <SelectField
            name="action"
            id="PlayerCreateAdjustmentForm__action"
            title={t(form.actionLabel)}
            options={actionOptions}
          />
          <TextField
            name="amount"
            id="PlayerCreateAdjustmentForm__amount"
            title={t(form.amountLabel)}
            required
          />
          <SelectField
            name="adjustmentType"
            id="PlayerCreateAdjustmentForm__adjustmentType"
            title={t(form.adjustmentTypeLabel)}
            options={adjustmentTypeOptions}
            required
          />
          <TextareaField
            name="comment"
            id="PlayerCreateAdjustmentForm__comment"
            title={t(form.commentLabel)}
            rows={4}
          />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={
              adjustmentDepositState.fetching ||
              adjustmentWithdrawalState.fetching
            }
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerCreateAdjustmentForm;
