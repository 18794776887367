import classNames from 'classnames';
import React from 'react';

import { InlineIconButton } from '@/components/InlineIconButton';
import { ClipboardListIcon } from './icons';

type BulkButtonProps = {
  onClick: () => void;
  clicked: boolean;
};

export const BulkButton = (props: BulkButtonProps) => {
  const { onClick, clicked } = props;

  return (
    <InlineIconButton
      onClick={onClick}
      className={classNames(`mt-1 p-2 ml-1 border border-gray-400 rounded-md`, {
        'bg-gray-700': clicked,
      })}
      type="button"
    >
      <ClipboardListIcon />
    </InlineIconButton>
  );
};
