import classNames from 'classnames';
import { Link } from 'gatsby';
import _ from 'lodash';
import React, { FC, ReactNode } from 'react';
import { useLocalStorage } from 'react-use';

import {
  CardOptionsButton,
  InlineIconButton,
  LoadingTable,
} from '@/components';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CogIcon,
  FilterIcon,
  SelectorIcon,
} from '@/components/icons';
import { useMultiSelect } from '@/hooks/useMultiSelect';
import { Nullable } from '@/types';
import { ColumnForm } from './ColumnForm';
import { FilterForm } from './FilterForm';
import { DataDef, FilterType } from './types';
import * as values from './values';

const getFilters = <O, T extends Record<string, any>>(
  defs: DataDef<O, T>[],
) => {
  return defs.reduce<FilterType[]>((acc, ref) => {
    if (ref.filter) {
      acc.push(ref.filter);
    }
    return acc;
  }, []);
};

export const useDataGrid = <O, T extends {}>({
  data,
  defaultFilter,
  desc,
  externalFilters = [],
  fetching,
  getRowLink,
  name,
  orderBy,
  schema: schemaFn,
  setOrderBy,
  updateFilter,
  includeCheckboxColumn = false,
  accessDenied,
  missingDataMessage = accessDenied ? 'Permissions required' : 'No entries',
}: {
  data: (T | undefined)[] | undefined;
  defaultFilter: {};
  desc: boolean;
  externalFilters?: FilterType[];
  fetching: boolean;
  getRowLink?: (value: { row: T }) => Nullable<string>;
  /** used for storing visible columns */
  name: string;
  orderBy?: O;
  schema: (creator: typeof values) => DataDef<O, T>[];
  setOrderBy: (order: O) => void;
  updateFilter: (values: {}) => void;
  accessDenied?: boolean;
  includeCheckboxColumn?: boolean;
  missingDataMessage?: string;
}) => {
  const schema = schemaFn(values);
  const { selected, allSelected, isSelected, onChange, onChangeAll } =
    useMultiSelect(data?.length || 0);

  const defaultVisibleColumns = schema.reduce<string[]>((acc, def) => {
    if (!def.hidden) {
      acc.push(def.field);
    }
    return acc;
  }, []);

  const schemaByKey = _.keyBy(schema, 'field');

  const [storedColumns, setStoredColumns, removeStoredColumns] =
    useLocalStorage<string[]>(`DataGrid_${name}`);

  const visibleColumns = storedColumns || defaultVisibleColumns;

  const allFilters = [...getFilters(schema), ...externalFilters];

  const columns = visibleColumns
    .map((field) => schemaByKey[field])
    .filter(Boolean);

  const GlobalColumnForm: FC<{ children: ReactNode }> = ({ children }) =>
    schema.length ? (
      <ColumnForm
        schema={schema}
        visibleColumns={visibleColumns}
        updateVisibleColumns={setStoredColumns}
        resetVisibleColumns={removeStoredColumns}
      >
        {children}
      </ColumnForm>
    ) : null;

  const GlobalFilterForm: FC<{ children: ReactNode }> = ({ children }) =>
    allFilters.length ? (
      <FilterForm
        filters={allFilters}
        defaultValues={defaultFilter}
        updateFilters={updateFilter}
      >
        {children}
      </FilterForm>
    ) : null;

  const GlobalCardOptions = () => (
    <>
      <GlobalColumnForm>
        <CardOptionsButton>
          <CogIcon />
        </CardOptionsButton>
      </GlobalColumnForm>
      <GlobalFilterForm>
        <CardOptionsButton>
          <FilterIcon />
        </CardOptionsButton>
      </GlobalFilterForm>
    </>
  );

  const Header: FC<{
    property: Nullable<O>;
    name?: Nullable<string>;
    children: ReactNode;
  }> = ({ property, children }) => {
    function onClick() {
      property && setOrderBy(property);
    }
    const icon =
      orderBy === property ? (
        !desc ? (
          <ChevronUpIcon className="text-black dark:text-gray-200 inline-block" />
        ) : (
          <ChevronDownIcon className="text-black dark:text-gray-200 inline-block" />
        )
      ) : (
        <SelectorIcon className="inline-block'" />
      );

    return (
      <th className="p-3 whitespace-nowrap text-gray-400 dark:text-gray-200">
        <div className="inline-flex justify-between items-center w-full">
          <span className="text-black dark:text-gray-200">{children}</span>
          {property && (
            <InlineIconButton onClick={onClick}>{icon}</InlineIconButton>
          )}
        </div>
      </th>
    );
  };

  const header = (
    <tr className="text-sm font-semibold divide-x dark:divide-gray-800">
      {includeCheckboxColumn && <th />}
      {columns.map(({ title, orderBy, field }) => (
        <Header key={field} property={orderBy}>
          {title}
        </Header>
      ))}
    </tr>
  );

  const filterHeader = allFilters.length ? (
    <tr className="text-sm font-semibold divide-x dark:divide-gray-800">
      {includeCheckboxColumn && (
        <th className="p-3 whitespace-nowrap">
          <input type="checkbox" onChange={onChangeAll} checked={allSelected} />
        </th>
      )}
      {columns.map((def) => (
        <th className="p-3 whitespace-nowrap" key={def.field}>
          {def.filter && (
            <div className="flex items-center justify-between">
              <div className="space-x-1">
                {def.filter.pillView &&
                  def.filter.pillView(defaultFilter, updateFilter)}
              </div>
              <FilterForm
                filters={[def.filter]}
                defaultValues={defaultFilter}
                updateFilters={updateFilter}
              />
            </div>
          )}
        </th>
      ))}
    </tr>
  ) : null;

  const rows =
    data?.length === 0 ? (
      <tr className="text-5xl text-gray-900 dark:text-white opacity-50">
        <td className="w-full text-center" colSpan={columns.length}>
          <div className="p-8">{missingDataMessage}</div>
        </td>
      </tr>
    ) : (
      (data || []).map(
        (row, index) =>
          row && (
            <tr
              className="hover:bg-gray-100 dark:hover:bg-gray-900"
              key={index}
            >
              {includeCheckboxColumn && (
                <td>
                  <div className="p-3">
                    <input
                      type="checkbox"
                      value={index}
                      checked={isSelected(index)}
                      onChange={onChange}
                    />
                  </div>
                </td>
              )}

              {columns.map(
                ({ component: C, linkTo, wrap: WrapperComponent }, index) => {
                  const innerChild = <C row={row} />;
                  const children = WrapperComponent ? (
                    <WrapperComponent row={row}>{innerChild}</WrapperComponent>
                  ) : (
                    innerChild
                  );
                  const linkTarget =
                    (linkTo && linkTo({ row })) ||
                    (getRowLink && getRowLink({ row }));
                  return (
                    <td
                      className={classNames(
                        'text-sm text-gray-800 dark:text-gray-200 truncate',
                        {
                          'p-3': !linkTarget,
                        },
                      )}
                      key={index}
                    >
                      {linkTarget ? (
                        <Link
                          className="w-full h-full p-3 block"
                          to={linkTarget}
                        >
                          {children}
                        </Link>
                      ) : (
                        children
                      )}
                    </td>
                  );
                },
              )}
            </tr>
          ),
      )
    );

  const DataGrid = () => {
    return (
      <div className="overflow-x-auto">
        <table className="w-full text-left border-collapse">
          <thead className="bg-gray-50 dark:bg-gray-900 border-gray-200 dark:border-gray-700 border-b p-2 divide-y divide-gray-200 dark:divide-gray-800">
            {header}
            {filterHeader}
          </thead>
          <tbody className="divide-y divide-gray-100 dark:divide-gray-800">
            {fetching ? (
              <LoadingTable rows={20} columns={columns.length} />
            ) : (
              rows
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return {
    DataGrid,
    GlobalFilterForm,
    GlobalColumnForm,
    visibleColumns,
    GlobalCardOptions,
    selectedRows: data ? selected.map((i) => data[i]) : [],
  };
};
