import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { InviteByPhoneNumberField } from '@/components/form/InviteByPhoneNumberField';
import { useTranslate } from '@/contexts';
import { BrandEnum } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { InvitePlayersToVipBrandMutation } from './__generated__/InviteVipPlayersForm';

const query = graphql`
  query SanityInviteVipPlayersForm {
    sanityInviteVipPlayersForm {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const playerMutation = gql`
  mutation InvitePlayersToVipBrand(
    $phoneNumbers: [PhoneNumber!]!
    $brand: BrandEnum!
  ) {
    invitePlayersToVipBrand(phoneNumbers: $phoneNumbers, brand: $brand)
  }
`;

type FormValues = {
  brand: string;
  phoneNumbers: [string];
};

const brandOptions = [{ label: BrandEnum.DJACK, value: BrandEnum.DJACK }];

const InviteVipPlayersForm = () => {
  const staticData =
    useStaticQuery<Queries.SanityInviteVipPlayersFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();
  const { t } = useTranslate();

  const form = staticData.sanityInviteVipPlayersForm;

  const [inviteVipPlayersState, inviteVipPlayers] =
    useMutation<InvitePlayersToVipBrandMutation>(playerMutation);

  const defaultValues: FormValues = {
    brand: BrandEnum.DJACK,
    phoneNumbers: [''],
  };

  const onSubmit = (values: typeof defaultValues) => {
    inviteVipPlayers({
      brand: values.brand,
      phoneNumbers: values.phoneNumbers,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <SelectField title="Brand" name="brand" options={brandOptions} />
          <InviteByPhoneNumberField
            title="Phone Number(s)"
            name="phoneNumbers"
            required
          />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value="Submit"
            disabled={inviteVipPlayersState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default InviteVipPlayersForm;
