import classNames from 'classnames';
import { addDays, formatDuration, formatISO } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useMemo, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'urql';

import { useCurrencies } from '@/bits';
import {
  Button,
  Card,
  CardCloseButton,
  CardOptions,
  DateTimeField,
  ErrorMessage,
  GameSearch,
  Loading,
  NakedForm,
  NumberField,
  SelectField,
  SelectOption,
  TextField,
  TextareaField,
  useModalContext,
} from '@/components';
import { SubmitButton } from '@/components/form/SubmitButton';
import { useTranslate } from '@/contexts';
import {
  ClaimType,
  GameProvider,
  RewardTypeEnum,
  Scalars,
} from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import { convertValuesToNumbers, rewardGameProviders } from '@/utils';
import { assert } from '@/utils/error';
import {
  CreatePlayerBonusMoneyDropMutation,
  CreatePlayerBonusMoneyDropMutationVariables,
  CreatePlayerFreeSpinsMutation,
  CreatePlayerFreeSpinsMutationVariables,
  CreatePlayerMoneyDropMutation,
  CreatePlayerMoneyDropMutationVariables,
  PlayerRewardCreateFormQuery,
} from './__generated__/PlayerRewardCreateForm';
import { usePlayerRewardCalculator } from './usePlayerRewardCalculator';

const query = graphql`
  query SanityPlayerRewardCreateForm {
    sanityPlayerRewardCreateForm {
      title {
        ...SanityLocaleString
      }
      rewardCreated {
        ...SanityLocaleString
      }
      rewardType {
        ...SanityLocaleString
      }
      rewardType {
        ...SanityLocaleString
      }
      value {
        ...SanityLocaleString
      }
      currency {
        ...SanityLocaleString
      }
      numberOfSpins {
        ...SanityLocaleString
      }
      paylines {
        ...SanityLocaleString
      }
      game {
        ...SanityLocaleString
      }
      gameProviders {
        ...SanityLocaleString
      }
      nameOfReward {
        ...SanityLocaleString
      }
      startDate {
        ...SanityLocaleString
      }
      endDate {
        ...SanityLocaleString
      }
      claimableInstant {
        ...SanityLocaleString
      }
      notes {
        ...SanityLocaleString
      }
      customReward {
        ...SanityLocaleString
      }
      expiresIn {
        ...SanityLocaleString
      }
      maxPayout {
        ...SanityLocaleString
      }
      wageringRequirementFactor {
        ...SanityLocaleString
      }
      autoCalculateReward {
        ...SanityLocaleString
      }
    }
  }
`;

const createFreeSpinsMutation = gql`
  mutation CreatePlayerFreeSpins(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $spinsNumber: Int!
    $spinsValue: Long!
    $betLevel: Int!
    $currencyCode: ISOCurrencyCode!
    $gameProviderId: GameProvider!
    $gameMobileId: String
    $gameDesktopId: String
  ) {
    createFreeSpins(
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      spinsNumber: $spinsNumber
      spinsValue: $spinsValue
      betLevel: $betLevel
      currencyCode: $currencyCode
      gameProviderId: $gameProviderId
      gameMobileId: $gameMobileId
      gameDesktopId: $gameDesktopId
    )
  }
`;

const createMoneyDropMutation = gql`
  mutation CreatePlayerMoneyDrop(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $amount: PositiveBigDecimal!
    $currencyCode: ISOCurrencyCode!
  ) {
    createMoneyDrop(
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      amount: $amount
      currencyCode: $currencyCode
    )
  }
`;

const createBonusMoneyDropMutation = gql`
  mutation CreatePlayerBonusMoneyDrop(
    $rewardName: String!
    $claimType: ClaimType!
    $startsAt: OffsetDateTime!
    $endsAt: OffsetDateTime
    $expiresIn: Duration
    $comment: String!
    $playerIds: [String!]!
    $amount: PositiveBigDecimal!
    $currencyCode: ISOCurrencyCode!
    $maxPayout: PositiveBigDecimal!
    $wageringRequirementFactor: Int!
  ) {
    createBonusMoneyDrop(
      rewardName: $rewardName
      claimType: $claimType
      startsAt: $startsAt
      endsAt: $endsAt
      expiresIn: $expiresIn
      comment: $comment
      playerIds: $playerIds
      amount: $amount
      currencyCode: $currencyCode
      maxPayout: $maxPayout
      wageringRequirementFactor: $wageringRequirementFactor
    )
  }
`;

const playerQuery = gql`
  query PlayerRewardCreateForm($playerId: ID!) {
    player(playerId: $playerId) {
      id
      uuid
      firstName
      lastName
    }
  }
`;

type Rewards = 'FreeSpinsType' | 'MoneyDropType' | 'BonusMoneyDropType';

type RewardOption = SelectOption & { value: Rewards };

type FormValues = {
  rewardName: string;
  rewardType: RewardTypeEnum;
  claimType: ClaimType;
  startsAt: Date;
  endsAt?: Date | null;
  expiresIn?: Duration | null;
  comment: string;
  spinsNumber: number | null;
  value: number | null;
  amount: number | null;
  bonusAmount: number | null;
  betLevel: number;
  gameProvider: GameProvider;
  currencyCode: Scalars['ISOCurrencyCode']['input'];
  gameDetails: {
    mobileId?: string | null;
    desktopId?: string | null;
  };
  maxPayout?: number | null;
  wageringRequirementFactor?: number | null;
};

const useOptions = () => {
  return useMemo(() => {
    const rewardTypesOptions: RewardOption[] = [
      {
        label: 'FreeSpins',
        value: RewardTypeEnum.FreeSpinsType,
      },
      {
        label: 'MoneyDrop',
        value: RewardTypeEnum.MoneyDropType,
      },
      {
        label: 'BonusMoneyDrop',
        value: RewardTypeEnum.BonusMoneyDropType,
      },
    ];

    const claimOptions: SelectOption[] = [
      {
        label: 'Claimable',
        value: 'Manual',
      },
      {
        label: 'Instant',
        value: 'Instant',
      },
    ];

    return {
      rewardTypesOptions,
      claimOptions,
    };
  }, []);
};

const PlayerRewardCreateForm: FC<{ playerId: string }> = ({ playerId }) => {
  const { t } = useTranslate();
  const staticData =
    useStaticQuery<Queries.SanityPlayerRewardCreateFormQuery>(query);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();
  const { close } = useModalContext();

  const {
    data: { rewardAmount, value, nrOfSpins, maxWin, wagering, currency },
    fetching,
  } = usePlayerRewardCalculator(playerId);

  const form = staticData.sanityPlayerRewardCreateForm;
  assert(form, 'missing form data');

  const { rewardTypesOptions, claimOptions } = useOptions();
  const { currencyOptions } = useCurrencies();

  const [{ data }] = useQuery<PlayerRewardCreateFormQuery>({
    query: playerQuery,
    variables: { playerId },
  });

  const [createFreeSpinsState, createFreeSpins] = useMutation<
    CreatePlayerFreeSpinsMutation,
    CreatePlayerFreeSpinsMutationVariables
  >(createFreeSpinsMutation);

  const [createMoneyDropState, createMoneyDrop] = useMutation<
    CreatePlayerMoneyDropMutation,
    CreatePlayerMoneyDropMutationVariables
  >(createMoneyDropMutation);

  const [createBonusMoneyDropState, createBonusMoneyDrop] = useMutation<
    CreatePlayerBonusMoneyDropMutation,
    CreatePlayerBonusMoneyDropMutationVariables
  >(createBonusMoneyDropMutation);

  const defaultValues: FormValues = {
    rewardType: RewardTypeEnum.FreeSpinsType,
    claimType: ClaimType.Manual,
    rewardName: `${t(form.customReward)} ${data?.player.firstName} ${
      data?.player.lastName
    }`,
    startsAt: new Date(),
    endsAt: addDays(new Date(), 14),
    expiresIn: { days: 3 },
    comment: '',
    spinsNumber: null,
    value: value || null,
    amount: null,
    bonusAmount: null,
    betLevel: 1,
    gameProvider: GameProvider.Relax,
    gameDetails: {
      mobileId: '',
      desktopId: '',
    },
    currencyCode: 'EUR',
    maxPayout: 1000,
    wageringRequirementFactor: 40,
  };

  const methods = useForm<FormValues>({
    defaultValues,
  });

  const { watch, setValue } = methods;

  const rewardType = watch('rewardType');
  const selectedGameProvider = watch('gameProvider');

  const isFreeSpins = rewardType === 'FreeSpinsType';
  const isBonusMoneyDrop = rewardType === 'BonusMoneyDropType';
  const isMoneyDrop = rewardType === 'MoneyDropType';

  // Temporary solution to disable non EUR currencies
  const isCurrencyEur = currency === 'EUR';

  const isAutoCalculateRewardVisible =
    isCurrencyEur && (isFreeSpins || isBonusMoneyDrop);

  const isAutoCalculateRewardDisabled = isFreeSpins ? !value : !rewardAmount;

  const onCalculateReward = () => {
    if (isFreeSpins) {
      setValue('spinsNumber', nrOfSpins);
      setValue('value', value);
    }
    if (isBonusMoneyDrop) {
      setValue('bonusAmount', rewardAmount);
      setValue('maxPayout', maxWin);
      setValue('wageringRequirementFactor', wagering);
    }
  };

  const onSubmit = (values: FormValues) => {
    if (values.rewardType === 'FreeSpinsType') {
      const variables: CreatePlayerFreeSpinsMutationVariables = {
        rewardName: values.rewardName,
        claimType: values.claimType,
        startsAt: formatISO(values.startsAt),
        endsAt: values.endsAt ? formatISO(values.endsAt) : null,
        comment: values.comment,
        playerIds: data?.player.uuid ? [data?.player.uuid] : [''],
        spinsNumber: Number(values.spinsNumber),
        spinsValue: Number(values.value),
        betLevel: Number(values.betLevel),
        currencyCode: values.currencyCode,
        gameProviderId: selectedGameProvider,
        gameMobileId: values.gameDetails.mobileId,
        gameDesktopId: values.gameDetails.desktopId,
      };

      return createFreeSpins(variables).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          feedback.success(t(form.rewardCreated));
          close();
        }
      });
    }

    if (values.rewardType === 'BonusMoneyDropType') {
      const variables: CreatePlayerBonusMoneyDropMutationVariables = {
        rewardName: values.rewardName,
        claimType: values.claimType,
        startsAt: formatISO(values.startsAt),
        endsAt: values.endsAt ? formatISO(values.endsAt) : null,
        expiresIn: values.expiresIn
          ? formatDuration(convertValuesToNumbers(values.expiresIn))
          : null,
        comment: values.comment,
        playerIds: data?.player.uuid ? [data?.player.uuid] : [''],
        amount: Number(values.bonusAmount),
        currencyCode: values.currencyCode,
        maxPayout: Number(values.maxPayout),
        wageringRequirementFactor: Number(values.wageringRequirementFactor),
      };

      return createBonusMoneyDrop(variables).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          feedback.success(t(form.rewardCreated));
          close();
        }
      });
    }

    const variables: CreatePlayerMoneyDropMutationVariables = {
      rewardName: values.rewardName,
      claimType: values.claimType,
      startsAt: formatISO(values.startsAt),
      endsAt: values.endsAt ? formatISO(values.endsAt) : null,
      comment: values.comment,
      playerIds: data?.player.uuid ? [data?.player.uuid] : [''],
      amount: Number(values.amount),
      currencyCode: values.currencyCode,
    };

    return createMoneyDrop(variables).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        feedback.success(t(form.rewardCreated));
        close();
      }
    });
  };

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="flex p-6">
        <NakedForm className="w-full" onSubmit={onSubmit} methods={methods}>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            <div className="grid gap-2 content-start">
              <SelectField
                name="rewardType"
                id="RewardPlayerCreateForm__rewardType"
                title={t(form.rewardType)}
                required
                options={rewardTypesOptions}
              />
              {isAutoCalculateRewardVisible && (
                <Button
                  className={classNames(
                    'shadow bg-green-500 hover:bg-green-400 focus:shadow-outline focus:outline-none disabled:bg-gray-500 disabled:cursor-default text-white font-bold py-2 px-4 rounded col-start-1',
                  )}
                  onClick={onCalculateReward}
                  disabled={fetching || isAutoCalculateRewardDisabled}
                  type="button"
                >
                  {fetching ? <Loading /> : t(form.autoCalculateReward)}
                </Button>
              )}
              <div className="grid sm:grid-cols-2 gap-2">
                {isFreeSpins && (
                  <div className="flex">
                    <TextField
                      name="value"
                      required
                      id="RewardPlayerCreateForm__value"
                      title={t(form.value) + ' (cents)'}
                      prefix="€"
                    />
                  </div>
                )}
                {isMoneyDrop && (
                  <TextField
                    name="amount"
                    required
                    id="RewardPlayerCreateForm__amount"
                    title={t(form.value)}
                  />
                )}
                {isBonusMoneyDrop && (
                  <TextField
                    name="bonusAmount"
                    required
                    id="RewardPlayerCreateForm__amount"
                    title={t(form.value)}
                  />
                )}
                <SelectField
                  name="currencyCode"
                  required
                  id="RewardPlayerCreateForm__currency"
                  title={t(form.currency)}
                  options={currencyOptions}
                />
              </div>
              {isFreeSpins && (
                <>
                  <div className="grid sm:grid-cols-2 gap-2">
                    <NumberField
                      name="spinsNumber"
                      required
                      id="RewardPlayerCreateForm__spinsNumber"
                      title={t(form.numberOfSpins)}
                      step="1"
                    />
                    <NumberField
                      name="betLevel"
                      required
                      id="RewardPlayerCreateForm__betLevel"
                      title={t(form.paylines)}
                      step="1"
                    />
                  </div>
                  <SelectField
                    name="gameProvider"
                    id="RewardPlayerCreateForm__gameProviders"
                    title={t(form.gameProviders)}
                    options={rewardGameProviders}
                  />
                  <GameSearch
                    name="gameDetails"
                    id="RewardPlayerCreateForm__gameDetails"
                    title={t(form.game)}
                    providerName={selectedGameProvider}
                  />
                </>
              )}
              {rewardType === 'BonusMoneyDropType' && (
                <>
                  <div className="grid sm:grid-cols-2 gap-2">
                    <TextField
                      name="maxPayout"
                      required
                      id="RewardCreateForm__maxPayout"
                      title={t(form.maxPayout)}
                    />
                    <NumberField
                      name="expiresIn.days"
                      required
                      id="RewardCreateForm__expiresIn"
                      title={t(form.expiresIn)}
                      step="1"
                    />
                  </div>
                  <div className="grid sm:grid-cols-2 gap-2">
                    <NumberField
                      name="wageringRequirementFactor"
                      required
                      id="RewardCreateForm__wageringRequirementFactor"
                      title={t(form.wageringRequirementFactor)}
                      step="1"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="grid gap-2 content-start">
              <TextField
                name="rewardName"
                id="RewardPlayerCreateForm__rewardName"
                title={t(form.nameOfReward)}
                required
              />
              <div className="grid sm:grid-cols-2 gap-2">
                <DateTimeField
                  title={t(form.startDate)}
                  name="startsAt"
                  id="analytics-block-settings__startDate"
                  required
                />
                <DateTimeField
                  title={t(form.endDate)}
                  name="endsAt"
                  id="analytics-block-settings__endDate"
                  required
                />
              </div>
              <SelectField
                name="claimType"
                id="RewardPlayerCreateForm__claimType"
                title={t(form.claimableInstant)}
                options={claimOptions}
                required
              />
            </div>
            <div className="grid gap-2 content-start">
              <TextareaField
                name="comment"
                title={t(form.notes)}
                id="RewardPlayerCreateForm__comment"
              />
            </div>
          </div>
          <ErrorMessage message={errorMessage} />
          <div className="flex justify-end">
            <SubmitButton
              value="Create Reward"
              disabled={
                createFreeSpinsState.fetching ||
                createMoneyDropState.fetching ||
                createBonusMoneyDropState.fetching
              }
            />
          </div>
        </NakedForm>
      </div>
    </Card>
  );
};

export default PlayerRewardCreateForm;
