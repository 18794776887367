import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SelectField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { MatchDecision, Maybe } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  SubmitMatchDecisionMutation,
  SubmitMatchDecisionMutationVariables,
} from './__generated__/SubmitMatchDecisionForm';

const query = graphql`
  query SanitySubmitMatchDecisionForm {
    sanitySubmitMatchDecisionForm {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const submitMatchDecisionMutation = gql`
  mutation SubmitMatchDecision(
    $playerId: ID!
    $resourceId: String!
    $matchDecision: MatchDecision!
  ) {
    submitMatchDecision(
      playerId: $playerId
      resourceId: $resourceId
      matchDecision: $matchDecision
    )
  }
`;

const SubmitMatchDecisionForm: FC<{
  playerId: string;
  resourceId: string;
  matchDecision: Maybe<MatchDecision>;
  fallback: () => void;
}> = ({ playerId, resourceId, matchDecision, fallback }) => {
  const staticData =
    useStaticQuery<Queries.SanitySubmitMatchDecisionFormQuery>(query);

  const { close } = useModalContext();
  const { t } = useTranslate();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanitySubmitMatchDecisionForm;

  const [changeMatchDecisionState, changeMatchDecision] = useMutation<
    SubmitMatchDecisionMutation,
    SubmitMatchDecisionMutationVariables
  >(submitMatchDecisionMutation);

  const matchDecisionOptions = Object.values(MatchDecision).map((option) => ({
    label: option,
    value: option,
  }));

  const defaultValues = {
    playerId,
    resourceId,
    matchDecision,
  };

  const onSubmit = (values: typeof defaultValues) => {
    if (!values.playerId) {
      return;
    }

    changeMatchDecision({
      playerId,
      resourceId,
      matchDecision: values.matchDecision as MatchDecision,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        fallback();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <SelectField
            name="matchDecision"
            id="submit-match-decision-form__match-decision"
            title="Select Match Decision"
            options={matchDecisionOptions}
          />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value="Submit"
            disabled={changeMatchDecisionState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default SubmitMatchDecisionForm;
