import React, {
  ReactHTMLElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { InputActionMeta, OptionProps } from 'react-select';
import AsyncSelect from 'react-select/async';

import { BulkButton } from '@/components/BulkButton';
import { BaseInputFieldProps } from '../BaseInputField';
import { BaseWrapper } from '../BaseWrapper';
import BulkImportField, { ItemProps, ListItemProps } from './BulkImportField';
import './react-select.css';
import SelectedList from './SelectedList';

interface SearchAndSelectProps extends BaseInputFieldProps {
  placeholder?: string;
  isMultiple?: boolean;
  loadOptions?: LoadOptions;
  component: ReactNode;
  config?: ConfigProps;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  isAsync?: boolean;
}

//TODO: This should be typed as OptionProps<OptionType> once the components are refactored
// Ticket: DEV-261
type TypeMe = any;

type OptionsFn = (
  inputValue: string,
  callback: (options: readonly object[]) => void,
) => void | Promise<readonly object[]>;

type LoadOptions = OptionsFn | undefined;

type ConfigProps = SharedConfigProps & (ConfigBulkProps | ConfigNonBulkProps);

type SharedConfigProps = {
  defaultAttrName: string;
  option: (item: OptionProps) => void;
};

type ConfigNonBulkProps = {
  enableBulk?: false | undefined;
};

type ConfigBulkProps = {
  listItem: (item: ListItemProps<any>) => ItemProps;
  enableBulk: true;
  onImportBulk: OnImportBulkProps;
};

type OnImportBulkProps = {
  (items: string[] | undefined): {
    results: any[] | undefined;
    loading: boolean;
  };
};

const Option = ({
  innerProps,
  component,
  ...props
}: TypeMe & { component: ReactNode }) => {
  const childComp = React.cloneElement(
    component as ReactHTMLElement<HTMLElement>,
    {
      ...props,
    },
  );

  return <div {...innerProps}>{childComp}</div>;
};

export const SearchAndSelectField = (props: SearchAndSelectProps) => {
  const { loadOptions, component, isMultiple, config, name } = props;
  const { setValue, control } = useFormContext();
  const [options, setOptions] = useState<any[]>([]);
  const [option, setOption] = useState<unknown>();
  const [showBulk, setShowBulk] = useState<boolean>(false);

  useEffect(() => {
    const values = options.map(({ itemId }) => itemId);
    setValue(name, values);
  }, [name, options, setValue]);

  useEffect(() => {
    setValue(name, option);
  }, [name, option, setValue]);

  const handleRemove = (itemId: string) => {
    const filteredItems = options.filter((option) => option.itemId !== itemId);
    setOptions(filteredItems);
  };

  const handleRemoveAll = () => {
    setOptions([]);
  };

  const onSelect = useCallback(
    (value: OptionProps) => {
      const newValue = config?.option(value);
      isMultiple ? setOptions(options.concat(newValue)) : setOption(newValue);
    },
    [config, isMultiple, options, setOptions],
  );

  const callbackList = (items: object[] | undefined) => {
    setOptions(options.concat(items));
  };

  const getValue = (field: ControllerRenderProps<FieldValues, string>) => {
    return isMultiple
      ? null
      : options.find(({ value }) => value === field.value);
  };

  return (
    <div className="flex flex-col md:flex-row md:space-x-4">
      <div className="w-full">
        <BaseWrapper title={props.title} name={props.name}>
          <Controller
            name={props.name}
            control={control}
            rules={{ required: props.required }}
            render={({ field }) => {
              return (
                <div className="flex w-full">
                  <AsyncSelect
                    className="w-full rounded-md"
                    classNamePrefix="react-asyncselect-override"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderColor: '',
                        borderRadius: '',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        marginLeft: '',
                        color: '',
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: '',
                      }),
                    }}
                    value={getValue(field)}
                    cacheOptions={true}
                    components={{
                      Option: (props: any) => (
                        <Option {...props} component={component} />
                      ),
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    onInputChange={props.onInputChange}
                    placeholder={props.placeholder || 'Search and select...'}
                    onChange={onSelect}
                    loadOptions={loadOptions}
                  />

                  {config?.enableBulk && (
                    <BulkButton
                      onClick={() => setShowBulk(!showBulk)}
                      clicked={showBulk}
                    />
                  )}
                </div>
              );
            }}
          />
          {config?.enableBulk && showBulk && (
            <BulkImportField
              list={callbackList}
              listItem={config.listItem}
              onImportBulk={config.onImportBulk}
              defaultAttrName={config.defaultAttrName}
            />
          )}
          {isMultiple && (
            <SelectedList
              options={options}
              removeItem={handleRemove}
              removeAll={handleRemoveAll}
            />
          )}
        </BaseWrapper>
      </div>
    </div>
  );
};
