import gql from 'graphql-tag';
import React from 'react';

import {
  Card,
  CardCloseButton,
  CardOptions,
  Date,
  DetailList,
} from '@/components';
import { Maybe } from '@/globalTypes';
import {
  ScreeningDetails_OverwritableStatusCheckFragment,
  ScreeningDetails_agentInitiatorFragment,
} from './__generated__/ScreeningDetails';

export const screeningDetails_agentInitiator = gql`
  fragment ScreeningDetails_agentInitiator on AgentInitiator {
    __typename
    agent {
      firstName
      lastName
    }
  }
`;

export const screeningDetails_overwritableStatusCheck = gql`
  fragment ScreeningDetails_OverwritableStatusCheck on OverwritableStatusCheck {
    overwrite {
      details
      decision
      madeBy {
        ...ScreeningDetails_agentInitiator
      }
      at
    }
    statusCheck {
      details
      decision
      checkedAt
    }
    kycProvider
    finalDecision
  }
  ${screeningDetails_agentInitiator}
`;

type Props = {
  title: string;
  screening: ScreeningDetails_OverwritableStatusCheckFragment;
};

const getMadeByName = (
  madeBy: Maybe<Partial<ScreeningDetails_agentInitiatorFragment>>,
): string => {
  if (!madeBy) {
    return '-';
  }

  const { firstName = '', lastName = '' } = madeBy.agent || {};

  return `${firstName} ${lastName}`.trim();
};

const ScreeningDetails = ({ title, screening }: Props) => {
  const madeBy = screening.overwrite?.madeBy;
  const agentName =
    madeBy?.__typename == 'AgentInitiator' ? getMadeByName(madeBy) : '-';

  return (
    <Card
      size="lg"
      title={title}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <DetailList
        items={[
          {
            label: 'Overwrite Decision',
            value: screening.overwrite
              ? screening.overwrite.decision
                ? 'Yes'
                : 'No'
              : '-',
          },
          {
            label: 'Overwrite Details',
            value: screening.overwrite?.details || '-',
          },
          {
            label: 'Overwrite By',
            value: agentName,
          },
          {
            label: 'Overwrite Date',
            value: <Date date={screening.overwrite?.at} />,
          },
          {
            label: 'Status Check',
            value: screening.statusCheck?.decision ? 'Yes' : 'No',
          },
          {
            label: 'Status Check Details',
            value: screening.statusCheck?.details || '-',
          },
          {
            label: 'Status Check Date',
            value: <Date date={screening.statusCheck?.checkedAt} />,
          },
          {
            label: 'KYC Provider',
            value: screening.kycProvider || '-',
          },
        ]}
      />
    </Card>
  );
};

export default ScreeningDetails;
