import { Link } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { useGetGlobalPlayerKyc6MatchesPageLink } from '@/bits/links/useLink';
import {
  Card,
  CardBody,
  CardCloseButton,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  InlineIconButton,
  Table,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  DocumentDownloadIcon,
  EyeIcon,
  NextIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { BrandEnum, KYC6MatchTypes, KYC6ReviewStatus } from '@/globalTypes';
import { renderTypeOfMatch } from '@/utils/typeOfMatch';
import useAggregatedKyc6Matches from './useAggregatedKYC6Matches';
import useAggregatedKYC6MatchReport from './useAggregatedKYC6MatchReport';

export const Fragment = graphql`
  fragment SanityKyc6MatchesBlock on SanityKyc6MatchesBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const Kyc6MatchesBlock: FC<{
  block: Queries.SanityKycValidationsBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();
  const report = useAggregatedKYC6MatchReport();

  const getGlobalPlayerKyc6MatchesLink =
    useGetGlobalPlayerKyc6MatchesPageLink();

  const {
    entries,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
    hasAccess,
  } = useAggregatedKyc6Matches();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'kyc6Matches',
    data: entries,
    fetching,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
    accessDenied: !hasAccess,
    schema: (s) => [
      s.stringValue({
        field: 'playerGlobalUUID',
        title: 'Player Global UUID',
        filterField: 'playerGlobalUUID',
        linkTo: ({ row }) =>
          getGlobalPlayerKyc6MatchesLink(row.playerGlobalId) ?? '',
      }),
      s.enumValue({
        field: 'brand',
        title: 'Brands',
        e: BrandEnum,
        wrap: ({ row }) => (
          <div className="flex items-center">
            {row.playerIdPerBrand.length < 3 ? (
              <span>
                {row.playerIdPerBrand
                  .map((player) => player.brand.name)
                  .join(', ')}
              </span>
            ) : (
              <>
                <span className="text-blue-600">
                  {row.playerIdPerBrand.length}
                </span>
                <ControlledModal
                  content={
                    <Card
                      title={'Brands'}
                      size="lg"
                      options={
                        <CardOptions>
                          <CardCloseButton />
                        </CardOptions>
                      }
                    >
                      <div className="p-3">
                        <Table
                          headings={['Player ID', 'Brand']}
                          rows={row.playerIdPerBrand.map((player, key) => ({
                            key: player.playerId,
                            data: [
                              <Link
                                key={`playerIdLink_${key}`}
                                className="w-full h-full p-3 block"
                                to={
                                  getGlobalPlayerKyc6MatchesLink(
                                    player.playerId,
                                  ) ?? ''
                                }
                              >
                                {player.playerUUID}
                              </Link>,
                              player.brand.name,
                            ],
                          }))}
                        />
                      </div>
                    </Card>
                  }
                >
                  <InlineIconButton>
                    <EyeIcon className="text-gray-400 text-lg" />
                  </InlineIconButton>
                </ControlledModal>
              </>
            )}
          </div>
        ),
      }),
      s.stringValue({
        field: 'maxScore',
        title: 'Max Score',
      }),
      s.stringValue({
        field: 'pepTier',
        title: 'Tier',
      }),
      s.enumValue({
        field: 'reviewStatus',
        title: 'Review Status',
        filterField: 'reviewStatus',
        e: KYC6ReviewStatus,
      }),
      s.enumValue({
        field: 'typeOfMatch',
        title: 'Type of Match',
        filterField: 'kyc6MatchTypes',
        e: KYC6MatchTypes,
        wrap: ({ row }) => renderTypeOfMatch(row.typeOfMatch),
      }),
      s.dateTimeValue({
        field: 'lastSearchedAt',
        title: 'Last Searched At',
        fromFilterField: 'from',
        toFilterField: 'to',
      }),
    ],
  });

  if (!block) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          {hasAccess && (
            <CardOptionsButton onClick={() => report.generate()}>
              <DocumentDownloadIcon />
            </CardOptionsButton>
          )}
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default Kyc6MatchesBlock;
