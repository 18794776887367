import gql from 'graphql-tag';
import { useQuery } from 'urql';

import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  VipBrandInvitedPlayersQuery,
  VipBrandInvitedPlayersQueryVariables,
} from './__generated__/useVipWhitelist';
import { queryParams } from './queryParams';

const VIP_WHITELIST_QUERY = gql`
  query VipBrandInvitedPlayers(
    $phoneNumber: String
    $email: String
    $brand: BrandEnum
    $initiatorId: String
    $initiatorType: InitiatorTypeEnum
    $from: OffsetDateTime
    $to: OffsetDateTime
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      vipBrandInvitedPlayers(
        phoneNumber: $phoneNumber
        email: $email
        brand: $brand
        initiatorId: $initiatorId
        initiatorType: $initiatorType
        from: $from
        to: $to
        after: $after
        before: $before
        first: $first
        last: $last
      ) {
        edges {
          node {
            phoneNumber
            email
            brand
            processedAt
            initiator {
              ... on AgentInitiator {
                agent {
                  firstName
                  lastName
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useVipWhitelist() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const [{ data, fetching }, refresh] = useQuery<
    VipBrandInvitedPlayersQuery,
    VipBrandInvitedPlayersQueryVariables
  >({
    query: VIP_WHITELIST_QUERY,
    variables: mapVariables(query),
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => {
      setQuery(values, 'replaceIn');
    },
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.vipBrandInvitedPlayers.pageInfo,
    ),
    brands: data?.viewer.vipBrandInvitedPlayers,
    invitedPlayers: data?.viewer.vipBrandInvitedPlayers?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
