import { formatISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { RangeDate } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { useCan } from '@/utils/access';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  GlobalPlayerKYC6MatchesQuery,
  GlobalPlayerKYC6MatchesQueryVariables,
} from './__generated__/useGlobalPlayerKYC6Matches';
import { queryParams } from './queryParams';

const QUERY = gql`
  query GlobalPlayerKYC6Matches(
    $playerGlobalId: ID!
    $score: RangeInt
    $lastSearchedAt: RangeDate
    $kyc6MatchTypes: [KYC6MatchTypes!]
    $matchDecision: MatchDecision
    $orderBy: KYC6MatchesOrderBy
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      brands {
        name
        code
      }
      kyc6MatchesByGlobalId(
        playerGlobalId: $playerGlobalId
        lastSearchedAt: $lastSearchedAt
        kyc6MatchTypes: $kyc6MatchTypes
        score: $score
        matchDecision: $matchDecision
        orderBy: $orderBy
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            playerId
            playerUUID
            brand {
              name
            }
            score
            typeOfMatch
            pepTier
            lastSearchedAt
            resourceId
            version
            matchDecision
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useGlobalPlayerKyc6Matches(playerGlobalId: string) {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const isAgentAllowedToAccessKyc6 = useCan('MANAGE_AND_ACCESS_KYC6');

  const lastSearchedAt = useMemo((): RangeDate | undefined => {
    if (query.lastSearchedAtFrom && query.lastSearchedAtTo) {
      return {
        from: formatISO(query.lastSearchedAtFrom),
        to: formatISO(query.lastSearchedAtTo),
      };
    }
    return undefined;
  }, [query.lastSearchedAtFrom, query.lastSearchedAtTo]);

  const variables = {
    playerGlobalId,
    ...mapVariables(query),
    lastSearchedAt,
  };

  const [{ data, fetching }, refresh] = useQuery<
    GlobalPlayerKYC6MatchesQuery,
    GlobalPlayerKYC6MatchesQueryVariables
  >({
    query: QUERY,
    variables,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    brands: data?.viewer.brands,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.kyc6MatchesByGlobalId.pageInfo,
    ),
    hasAccess: isAgentAllowedToAccessKyc6,
    entries: isAgentAllowedToAccessKyc6
      ? data?.viewer.kyc6MatchesByGlobalId?.edges?.map((edge) => edge?.node)
      : [],
  };
}
