import { formatISO, isAfter, parseISO, subWeeks } from 'date-fns';
import { gql, useQuery } from 'urql';

import { calculateBonus, calculateFreeSpins, calculateNetLoss } from '@/utils';
import {
  PlayerRewardCalculatorQuery,
  PlayerRewardCalculatorQueryVariables,
} from './__generated__/usePlayerRewardCalculator';

const QUERY = gql`
  query PlayerRewardCalculator($playerId: ID!) {
    player(playerId: $playerId) {
      id
      playerStats {
        currency
        playerCurrentSegment
        ngr
        lastBonusAt
        ngrSinceLastManualReward
      }
    }
  }
`;

export const usePlayerRewardCalculator = (playerId: string) => {
  const dateNow = new Date();
  const dateSixWeeksAgo = subWeeks(dateNow, 6);

  const [{ data, fetching }] = useQuery<
    PlayerRewardCalculatorQuery,
    PlayerRewardCalculatorQueryVariables
  >({
    query: QUERY,
    variables: {
      playerId,
    },
    requestPolicy: 'cache-and-network',
  });

  const playerStats = data?.player.playerStats;
  const playerCurrentSegment = playerStats?.playerCurrentSegment || '1';
  const ngr = playerStats?.ngr || 0;
  const lastBonusAt = playerStats?.lastBonusAt;
  const ngrSinceLastManualReward = playerStats?.ngrSinceLastManualReward || 0;
  const currency = playerStats?.currency;

  // Parse the date strings using parseISO
  const parsedLastBonusAt = lastBonusAt ? parseISO(lastBonusAt.toString()) : 0;
  const parsedDateSixWeeksAgo = parseISO(formatISO(dateSixWeeksAgo));

  const shouldUseLastBonusDate = isAfter(
    parsedLastBonusAt,
    parsedDateSixWeeksAgo,
  );

  const netLoss = calculateNetLoss(
    shouldUseLastBonusDate,
    ngr,
    ngrSinceLastManualReward,
  );

  const getFreeSpinsValues = calculateFreeSpins(playerCurrentSegment, netLoss);
  const getBonusMoneyDropValues = calculateBonus(playerCurrentSegment, netLoss);

  return {
    data: { ...getFreeSpinsValues, ...getBonusMoneyDropValues, currency },
    fetching,
  };
};
