import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { KYC6MatchTypesParam } from '@/utils/query/params';

export const queryParams = {
  aboutToExpireThresholdInDays: NumberParam,
  after: StringParam,
  before: StringParam,
  desc: withDefault(BooleanParam, true),
  first: withDefault(NumberParam, 20),
  last: NumberParam,
  kyc6MatchTypes: KYC6MatchTypesParam,
  lastSearchedAtFrom: DateTimeParam,
  lastSearchedAtTo: DateTimeParam,
};
