import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import { ScreeningType } from '@/blocks/player-screening-statuses-block/components';
import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SelectField,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  AddFraudStatusOverwriteMutation,
  AddPepStatusOverwriteMutation,
  AddSanctionedStatusOverwriteMutation,
} from './__generated__/PlayerAddStatusOverwriteForm';

const query = graphql`
  query SanityPlayerAddStatusOverwriteForm {
    sanityPlayerAddStatusOverwriteForm {
      title {
        ...SanityLocaleString
      }
    }
  }
`;

const addPepStatusOverwriteMutation = gql`
  mutation AddPepStatusOverwrite(
    $playerId: ID!
    $decision: Boolean!
    $details: String
  ) {
    addPepStatusOverwrite(
      playerId: $playerId
      decision: $decision
      details: $details
    ) {
      pep {
        overwrite {
          details
          decision
          madeBy {
            __typename
          }
          at
        }
        finalDecision
      }
    }
  }
`;

const addSanctionedStatusOverwriteMutation = gql`
  mutation AddSanctionedStatusOverwrite(
    $playerId: ID!
    $decision: Boolean!
    $details: String
  ) {
    addSanctionedStatusOverwrite(
      playerId: $playerId
      decision: $decision
      details: $details
    ) {
      sanctioned {
        overwrite {
          details
          decision
          madeBy {
            __typename
          }
          at
        }
      }
    }
  }
`;

const addFraudStatusOverwriteMutation = gql`
  mutation AddFraudStatusOverwrite(
    $playerId: ID!
    $decision: Boolean!
    $details: String
  ) {
    addFraudStatusOverwrite(
      playerId: $playerId
      decision: $decision
      details: $details
    ) {
      fraud {
        overwrite {
          details
          decision
          madeBy {
            __typename
          }
          at
        }
      }
    }
  }
`;

type Props = {
  playerId: string;
  screeningType: ScreeningType;
  fallback: () => void;
};

type FormValues = {
  decision: string;
  details: string;
};

const stringToBoolean = (str: string): boolean | undefined => {
  const map: { [key: string]: boolean } = {
    true: true,
    false: false,
  };

  return map[str.toLowerCase()];
};

const PlayerAddStatusOverwriteForm: FC<Props> = ({
  playerId,
  screeningType,
  fallback,
}) => {
  const { close } = useModalContext();
  const { t } = useTranslate();

  const staticData =
    useStaticQuery<Queries.SanityPlayerAddStatusOverwriteFormQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerAddStatusOverwriteForm;

  const [addPepStatusOverwriteState, addPepStatusOverwrite] =
    useMutation<AddPepStatusOverwriteMutation>(addPepStatusOverwriteMutation);

  const [addSanctionedStatusOverwriteState, addSanctionedStatusOverwrite] =
    useMutation<AddSanctionedStatusOverwriteMutation>(
      addSanctionedStatusOverwriteMutation,
    );

  const [addFraudStatusOverwriteState, addFraudStatusOverwrite] =
    useMutation<AddFraudStatusOverwriteMutation>(
      addFraudStatusOverwriteMutation,
    );

  const defaultValues: FormValues = {
    decision: 'true',
    details: '',
  };

  const onSubmit = (values: typeof defaultValues) => {
    if (screeningType === 'sanctioned') {
      addSanctionedStatusOverwrite({
        playerId,
        decision: values.decision,
        details: values.details,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          fallback();
          close();
        }
      });
    }

    if (screeningType === 'fraud') {
      addFraudStatusOverwrite({
        playerId,
        decision: values.decision,
        details: values.details,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error.message);
        } else if (close) {
          fallback();
          close();
        }
      });
    }

    addPepStatusOverwrite({
      playerId,
      decision: stringToBoolean(values.decision),
      details: values.details,
    }).then((res) => {
      if (res.error?.message && isMounted) {
        setErrorMessage(res.error.message);
      } else if (close) {
        fallback();
        close();
      }
    });
  };

  if (!form) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 gap-6"
        >
          <SelectField
            title="Overwrite Decision"
            name="decision"
            options={[
              { label: 'True', value: 'true' },
              { label: 'False', value: 'false' },
            ]}
          />
          <TextareaField title="Details" name="details" />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={'Submit'}
            disabled={
              addPepStatusOverwriteState.fetching ||
              addSanctionedStatusOverwriteState.fetching ||
              addFraudStatusOverwriteState.fetching
            }
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerAddStatusOverwriteForm;
