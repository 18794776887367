import { formatISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { RangeDate } from '@/globalTypes';
import { useQueryParamsWithTime } from '@/hooks';
import { mapVariables } from '@/utils';
import { useCan } from '@/utils/access';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  AggregatedKYC6MatchesQuery,
  AggregatedKYC6MatchesQueryVariables,
} from './__generated__/useAggregatedKYC6Matches';
import { queryParams } from './queryParams';

const QUERY = gql`
  query AggregatedKYC6Matches(
    $playerGlobalUUID: String
    $maxScore: RangeInt
    $lastSearchedAt: RangeDate
    $kyc6MatchTypes: [KYC6MatchTypes!]
    $reviewStatus: KYC6ReviewStatus
    $orderBy: AggregatedKYC6MatchesOrderBy
    $desc: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    viewer {
      id
      aggregatedKYC6Matches(
        playerGlobalUUID: $playerGlobalUUID
        maxScore: $maxScore
        lastSearchedAt: $lastSearchedAt
        kyc6MatchTypes: $kyc6MatchTypes
        reviewStatus: $reviewStatus
        orderBy: $orderBy
        desc: $desc
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            playerGlobalUUID
            playerGlobalId
            playerIdPerBrand {
              brand {
                name
              }
              playerId
              playerUUID
            }
            maxScore
            typeOfMatch
            pepTier
            lastSearchedAt
            reviewStatus
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`;

export default function useAggregatedKyc6Matches() {
  const [query, setQuery] = useQueryParamsWithTime(queryParams, {
    fromAndToOptional: true,
  });

  const isAgentAllowedToAccessKyc6 = useCan('MANAGE_AND_ACCESS_KYC6');

  const lastSearchedAt = useMemo((): RangeDate | undefined => {
    if (query.lastSearchedAtFrom && query.lastSearchedAtTo) {
      return {
        from: formatISO(query.lastSearchedAtFrom),
        to: formatISO(query.lastSearchedAtTo),
      };
    }
    return undefined;
  }, [query.lastSearchedAtFrom, query.lastSearchedAtTo]);

  const variables = {
    ...mapVariables(query),
    lastSearchedAt,
  };

  const [{ data, fetching }, refresh] = useQuery<
    AggregatedKYC6MatchesQuery,
    AggregatedKYC6MatchesQueryVariables
  >({
    query: QUERY,
    variables,
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.aggregatedKYC6Matches.pageInfo,
    ),
    hasAccess: isAgentAllowedToAccessKyc6,
    entries: isAgentAllowedToAccessKyc6
      ? data?.viewer.aggregatedKYC6Matches?.edges?.map((edge) => edge?.node)
      : [],
  };
}
