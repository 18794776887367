import { debounce } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { components } from 'react-select';

import { searchGames } from '@/hooks/useGamesSearch';
import { SearchAndSelectField } from '../form';

//TODO: This should be typed as OptionProps<OptionType> once the components are refactored
// Ticket: DEV-261
type TypeMe = any;

const OptionComponent = (props: TypeMe) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row w-full items-center p-1 cursor-pointer">
        {props.value.thumbnail ? (
          <picture className="flex-shrink-0">
            <img
              src={`${props.value.thumbnail}?u=${props.value.thumbnailUpdatedAt}`}
              alt=""
              loading="lazy"
              className="w-8 h-8 rounded-sm mr-2"
            />
          </picture>
        ) : (
          <div className="w-6 h-6 rounded-sm mr-2 bg-gray-300" />
        )}
        <div className="flex flex-col flex-o w-full">
          <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
            <div className="text-sm">{props.label}</div>
            <div className="text-xs">{props.value.slug}</div>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

const optionObject = ({ value }: TypeMe) => {
  return {
    itemId: `${value.studio}/${value.slug}`,
    name: value.name,
    mobileId: value.mobileId,
    desktopId: value.desktopId,
    providerName: value.provider,
  };
};

// Provider name should be typed as ProviderProps once it's fixed in BE
type Props = {
  id: string;
  name: string;
  title: string;
  providerName?: string;
};

export const GameSearch = ({ id, name, title, providerName }: Props) => {
  const handleLoadOptions = (
    value: string,
    callback: (options: readonly object[]) => void,
  ) => {
    const trimmedQuery = value.trim();
    const gamesPromise = searchGames(trimmedQuery, providerName);

    Promise.all([gamesPromise]).then(([gamesRes]) => {
      const finalGames =
        gamesRes?.map((item) => ({
          value: item,
          label: item.name,
        })) || [];

      callback(finalGames);
    });
  };

  const handleInputChangeRef = useRef(debounce(handleLoadOptions, 100)).current;

  useEffect(() => {
    return () => {
      handleInputChangeRef.cancel();
    };
  }, [handleInputChangeRef]);

  return (
    <SearchAndSelectField
      id={id}
      loadOptions={handleLoadOptions}
      isAsync
      name={name}
      title={title}
      component={<OptionComponent />}
      config={{
        defaultAttrName: 'uniqueGameId',
        option: optionObject,
      }}
    />
  );
};
