import { saveAs } from 'file-saver';
import { gql, useClient } from 'urql';
import { useQueryParams } from 'use-query-params';

import { mapVariables } from '@/utils';
import {
  AggregatedKyc6MatchReportQuery,
  AggregatedKyc6MatchReportQueryVariables,
} from './__generated__/useAggregatedKYC6MatchReport';
import { queryParams } from './queryParams';

const QUERY = gql`
  query AggregatedKyc6MatchReport(
    $playerGlobalUUID: String
    $maxScore: RangeInt
    $lastSearchedAt: RangeDate
    $kyc6MatchTypes: [KYC6MatchTypes!]
    $reviewStatus: KYC6ReviewStatus
    $orderBy: AggregatedKYC6MatchesOrderBy
    $desc: Boolean
  ) {
    viewer {
      id
      aggregatedKyc6MatchReportDownloadLink(
        playerGlobalUUID: $playerGlobalUUID
        maxScore: $maxScore
        lastSearchedAt: $lastSearchedAt
        kyc6MatchTypes: $kyc6MatchTypes
        reviewStatus: $reviewStatus
        orderBy: $orderBy
        desc: $desc
      ) {
        url
      }
    }
  }
`;

export default function useAggregatedKYC6MatchReport() {
  const [query] = useQueryParams(queryParams);
  const client = useClient();

  const generate = async () => {
    return client
      .query<
        AggregatedKyc6MatchReportQuery,
        AggregatedKyc6MatchReportQueryVariables
      >(QUERY, mapVariables(query))
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.aggregatedKyc6MatchReportDownloadLink.url;

        if (url) {
          saveAs(url, 'aggregated-kyc6-match-report.csv');
        }
      });
  };

  return {
    generate,
  };
}
