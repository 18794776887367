import { saveAs } from 'file-saver';
import { useCallback } from 'react';
import { gql, useClient } from 'urql';

import {
  Kyc6MatchReportDownloadLinkQuery,
  Kyc6MatchReportDownloadLinkQueryVariables,
} from './__generated__/useKyc6MatchReportDownloadLink';

const RG_RISK_ASSESSMENT_REPORT = gql`
  query Kyc6MatchReportDownloadLink($resourceId: String!, $version: Long) {
    viewer {
      kyc6MatchReportDownloadLink(resourceId: $resourceId, version: $version) {
        url
      }
    }
  }
`;

export default function useKyc6MatchReportDownloadLink() {
  const client = useClient();

  const generateDownloadLink = useCallback(
    async ({
      resourceId,
      version,
    }: {
      resourceId: string;
      version: number;
    }) => {
      return client
        .query<
          Kyc6MatchReportDownloadLinkQuery,
          Kyc6MatchReportDownloadLinkQueryVariables
        >(RG_RISK_ASSESSMENT_REPORT, { resourceId, version })
        .toPromise()
        .then((res) => {
          const url = res.data?.viewer.kyc6MatchReportDownloadLink.url;

          if (url) {
            saveAs(url);
          }
        });
    },
    [client],
  );

  return {
    generateDownloadLink,
  };
}
