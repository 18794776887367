import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  ControlledModal,
  Copy,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  NextIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import { InviteVipPlayersForm } from '@/forms';
import useVipWhitelist from './useVipWhitelist';

export const Fragment = graphql`
  fragment SanityVipWhitelistBlock on SanityVipWhitelistBlock {
    title {
      ...SanityLocaleString
    }
  }
`;

const VipWhitelistBlock: FC<{
  block: Queries.SanityRewardsHistoryBlockFragment;
}> = ({ block }) => {
  const { t } = useTranslate();

  const {
    invitedPlayers,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = useVipWhitelist();

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'vipWhitelist',
    data: invitedPlayers,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.stringValue({
        field: 'phoneNumber',
        title: 'Phone Number',
        getValue: ({ row }) => <Copy value={row.phoneNumber} />,
        filterField: 'phoneNumber',
      }),
      s.stringValue({
        field: 'brand',
        title: 'Brand',
        filterField: 'brand',
      }),
      s.stringValue({
        field: 'email',
        title: 'Email',
        filterField: 'email',
      }),
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Processed At',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: 'processedAt',
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Invited By',
        getValue: ({ row }) => row.initiator,
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal content={<InviteVipPlayersForm />}>
            <CardOptionsButton>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default VipWhitelistBlock;
